
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css"; // Import the CSS file
import { useOrderContext } from './../../../Context/ContextStore';
import { FaArrowLeft } from "react-icons/fa";

export const TeamLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { handleLogin, error, success } = useOrderContext();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(email, password);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const createAccount = () => {
    navigate('/team-Register');
  };

  const handleBackClick = () => {
    navigate('/');
  };

  return (

    <div className="oM-login-container">
        <button className="back-button" onClick={handleBackClick}>
    <FaArrowLeft /> Back
  </button>
  <div className="oM-login-form">
    <h2 className="oM-login-heading">Login</h2>
    {error && <p className="oM-login-message error">{error}</p>}
    {success && <p className="oM-login-message success">{success}</p>}
    <form onSubmit={handleSubmit}>
      <div className="oM-login-input-group">
        <label htmlFor="email">Email:</label>
        <input
          id="email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      <div className="oM-login-input-group oM-login-password-group">
        <label htmlFor="password">Password:</label>
        <div className="oM-login-password-container">
          <input
            id="password"
            type={showPassword ? "text" : "password"}
            value={password}
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button
            type="button"
            className="oM-login-password-toggle"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? "Hide" : "Show"}
          </button>
        </div>
      </div>

      <button type="submit" className="oM-login-btn">
        Login
      </button>
    </form>
    <p className="oM-login-signup-text">
      Don't have an account?{" "}
      <span className="oM-login-create-account" onClick={createAccount}>
        Sign Up
      </span>
    </p>
  </div>
</div>

  );
};
