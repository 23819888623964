
import React, { useState } from 'react';
import axios from 'axios';
import './Register.css'; // Import the updated CSS file
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

export const TeamRegister = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phonenumber: '',
    password: '',
    role: 'caller',
    otp: ''
  });

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);

  const validateInputs = () => {
    const errors = {};
    const { name, email, phonenumber, password } = formData;

    // Name validation
    const nameRegex = /^[A-Za-z\s]+$/; // Allows only letters and spaces
    if (!nameRegex.test(name)) {
      errors.name = 'Name should contain only letters and spaces';
    } else if (name.replace(/\s/g, '').length < 2) {
      errors.name = 'Name should contain at least two alphabetic letters';
    }

    // Email validation (valid email format)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      errors.email = 'Please enter a valid email address.';
    }

    // Phone number validation (10 digits)
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phonenumber)) {
      errors.phonenumber = 'Phone number must be exactly 10 digits.';
    }

    // Password validation (at least 8 characters, 1 letter, 1 special character)
    const passwordRegex = /^(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      errors.password = 'Must be at least 8 characters long and 1 capital and 1 special character.';
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault(); // Prevent form from submitting and refreshing the page
    if (!validateInputs()) return; // Validate inputs before proceeding

    try {
      // console.log('Form Data:', formData);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/register`, formData);
      // console.log('Registration response:', response); 
      setIsOtpSent(true);
      setSuccess('Registration successful! OTP sent to your email.');
      setError({});
    } catch (error) {
      // console.error('Error during registration:', error); 

      // Check if the error is a response from the server
      if (error.response) {
        // console.log('Error response:', error.response); 
        const status = error.response.status;
        const errorMessage = error.response.data?.message || 'Registration failed.';

        // Check if the error is related to email already existing
        if (status === 400 && error.response.data?.message === 'Email already exists') {
          setSuccess('');
          setError({ general: 'This email is already registered. Please try a different one.' });
        } else {
          setSuccess('');
          setError({ general: errorMessage });
        }

        // console.log('Error status:', status); 
        // console.log('Error message:', errorMessage); 
      } else {
        // Handle other types of errors (e.g., network error)
        // console.error('Unknown error:', error); 
        setError({ general: 'An unknown error occurred. Please try again later.' });
      }
    }
  };

  const handleVerifyOtp = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/verify-otp`, { email: formData.email, otp: formData.otp });
      // Redirect to the login page after OTP verification
      setTimeout(() => {
        navigate('/team-login');
      }, 1000);
      setSuccess('OTP verified successfully!');
      setError({});
    } catch (error) {
      setError({ general: 'OTP verification failed' });
      setSuccess('');
    }
  };

  const togglePasswordVisibility = (event) => {
    event.preventDefault(); // Prevent form submission when toggling password visibility
    setShowPassword(!showPassword);
  };

  const handleBackClick = () => {
    navigate('/team-login');
  };

  return (
    <div className="oM-register-page">
      <button className="back-button" onClick={handleBackClick}>
        <FaArrowLeft /> Back
      </button>
      <div className="oM-form-container">
        <div className="oM-register-form">
          <h2 className="oM-register-heading">Register</h2>
          {error.general && <p className="oM-register-message error">{error.general}</p>}
          {success && <p className="oM-register-message success">{success}</p>}

          {!isOtpSent ? (
            <>
              <form onSubmit={handleRegister}>
                <div>
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    autoComplete="text"
                    onChange={handleChange}
                  />
                  {error.name && <p className="oM-errorInput">{error.name}</p>}
                </div>
                <div>
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {error.email && <p className="oM-errorInput">{error.email}</p>}
                </div>
                <div>
                  <label>Phone Number:</label>
                  <input
                    type="text"
                    name="phonenumber"
                    autoComplete="text"
                    value={formData.phonenumber}
                    onChange={handleChange}
                  />
                  {error.phonenumber && <p className="oM-errorInput">{error.phonenumber}</p>}
                </div>
                <div className="oM-password-container">
                  <label>Password:</label>
                  <div className="oM-signup-passowrd-container">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={formData.password}
                      autoComplete="current-password"
                      onChange={handleChange}
                    />
                    <button className="oM-sigup-toggle-password" onClick={togglePasswordVisibility}>
                      {showPassword ? 'Hide' : 'Show'}
                    </button>
                  </div>
                  {error.password && <p className="oM-errorInput">{error.password}</p>}
                </div>
                <div>
                  <label>Role:</label>
                  <select name="role" value={formData.role} onChange={handleChange}>
                    <option value="delivery boy">Delivery Hero</option>
                    <option value="assigner">Fleet Manager</option>
                  </select>
                </div>
                <button className="oM-register-button">Register</button>
                <p className="oM-ptagtext">
                  Already have an account? <span className="oM-LoginClick" onClick={() => navigate('/team-login')}>Login</span>
                </p>
              </form>
            </>
          ) : (
            <div className="otp-form">
              <label>Enter OTP:</label>
              <input
                type="text"
                name="otp"
                value={formData.otp}
                onChange={handleChange}
              />
              <button className="oM-register-button" onClick={handleVerifyOtp}>Verify OTP</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
