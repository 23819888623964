


import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './Carousel.css';
import DeliveryImage from '../../Images/img/Delivery_carousel.webp';
import GiftDeliveryImg from '../../Images/img/Delivery_carousel2.webp';
import AnythingAnywhereImg from '../../Images/img/Anything_Anywhere.webp';
import CakeDeliveryImg from '../../Images/img/CakeDelivery.webp';

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const carouselItems = [
    {
      heading: "Send Any documents or packages anywhere in city limits",
      text: "Documents and packages delivered safely and promptly to your doorstep!",
      btn1: "Call To Order",
      btn2: "Whatsapp Now",
      image: DeliveryImage,
      alt: "Send any documents or packages anywhere in city limits - TURTU",
      url: "https://turtu.in/send-documents"
    },
    {
      heading: "Anything Anywhere",
      text: "You can send #parcels and packages  #documents #food #cake and etc.",
      btn1: "Call To Order",
      btn2: "Whatsapp Now",
      image: AnythingAnywhereImg,
      alt: "Send parcels, packages, documents, food, cake - TURTU",
      url: "https://turtu.in/anything-anywhere"
    },
    {
      heading: "Worried about safe cake deliveries?",
      text: "We are the best, safest cake deliverers Pan INDIA. Book your cake delivery now",
      btn1: "Call To Order",
      btn2: "Whatsapp Now",
      image: CakeDeliveryImg,
      alt: "Safe cake deliveries Pan INDIA - TURTU",
      url: "https://turtu.in/safe-cake-deliveries"
    },
    {
      heading: "Send Gifts and flowers to your loved ones",
      text: "Now send any gifts and flowers at affordable prices across the city.",
      btn1: "Call To Order",
      btn2: "Whatsapp Now",
      image: GiftDeliveryImg,
      alt: "Send gifts and flowers across the city - TURTU",
      url: "https://turtu.in/send-gifts-flowers"
    }
  ];

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <HelmetProvider>
      <section className='carousel-section' id='home'>
        <Helmet>
          {carouselItems.map((item, index) => (
            <script key={index} type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "ImageObject",
                "contentUrl": item.image,
                "description": item.alt,
                "name": item.heading,
                "url": item.url
              })}
            </script>
          ))}
        </Helmet>
        <div className="carousel-container">
          <div className="carousel-slide">
            <div className="carousel-image">
              <img src={carouselItems[activeIndex].image} alt={carouselItems[activeIndex].alt} />
            </div>
            <div className="carousel-content">
              <h2>{carouselItems[activeIndex].heading}</h2>
              <p>{carouselItems[activeIndex].text}</p>
            </div>
          </div>
          <div className="carousel-decor"></div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Carousel;

