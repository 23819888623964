import React from "react";
import "./About.css";
import aboutusImage from '../../Images/img/aboutUs.webp';

import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
const About = () => {

 
  return (
    <>
  <Header/>
    <section className="about-us" id="about">
 {/* <button className="back-button" onClick={handleBackClick}>
            <FaArrowLeft /> Back
          </button> */}

      <div className="about-heading">
        <h2>About Us</h2>
      </div>

      <div className="about-container">
        <div className="about-row">
          <div className="about-image">
            <img src={aboutusImage} alt="About Us" />
          </div>
          <div className="about-content">
            <div className="about-card">
              <h4>Welcome to <span>Turtu Services</span></h4>
              <p>
               At TURTU, we are on a mission to redefine hyperlocal delivery,
               bridging the gap between local businesses and consumers in India’s untapped tier 2 and 3 cities.
               Our platform is designed to provide seamless connectivity, unbeatable value, and scalable growth opportunities.
               and when it comes to  B2C we are delivering utmost convenience by ensuring anything delivered anywhere.
              </p>
            </div>
            <div className="about-card">
              <h4>Who <span>Are We?</span></h4>
              <p>
                Tired of hopping between different applications for various on-demand services? With TURTU, you can access many essential on-demand services under one roof.
              </p>
            </div>
            <div className="about-card">
              <h4><span>Vision</span></h4>
              <p>
              To become the go-to online delivery 
              application and to bring affordable options to the 
              unpenetrated section of the country
              </p>
            </div>
            <div className="about-card">
              <h4><span>Mission</span></h4>
              <p>
                To provide quick, safe, trustworthy, reliable, and time-saving essential on-demand, hyperlocal, and last-mile deliveries to all tier 2 and 3 cities of India.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default About;
